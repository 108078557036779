@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500&display=swap");

$text-color: #ffffff;

$black-primary: #0c0c0c;
$black-secondary: #0c0c0c;
$border-color: #4c4c4c;

$breakpoint-medium: 1224px;
$breakpoint-small: 520px;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: $black-primary;
  background-attachment: fixed;
  color: $text-color;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.1px;
}

@mixin line-clamp($number) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $number;
  -webkit-box-orient: vertical;
}

::-webkit-scrollbar {
  width: 0px;
}
