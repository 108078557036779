.comments__form {
  img {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
  input {
    background: transparent;
    border: none;
    border-bottom: 2px solid #353956;
    color: #fff;
    &:focus {
      background: transparent;
      border: none;
      border-bottom: 1px solid rgb(237, 237, 237);
      outline: none;
    }
  }
  button {
    background-color: rgb(0, 128, 255);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    letter-spacing: 1px;
    font-weight: 500;
    border-radius: 75px 75px;
    color: white;
    &:hover {
      background-color: rgb(20, 124, 193);
    }
  }
}
