@import "../../_base.scss";

.CategoriesBar {
  padding: 0.5rem 0;
  font-size: 0.8rem;
  display: flex;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0px;
  }
  scrollbar-width: none;

  span {
    margin-right: 1rem;
    padding: 0.6rem;
    font-weight: 500;
    font-size: 0.81rem;
    white-space: nowrap;
    letter-spacing: 0.6px;
    border: 1.5px solid rgb(67, 70, 71);
    border-radius: 9px 9px;

    &:hover {
      background-color: #4a4e51;
    }
    &.active {
      color: rgb(0, 0, 0);
      background-color: #dfdddd;
      border-color: $border-color;
    }
  }
}
