@import "../../_base.scss";

.video {
  margin-bottom: 1rem;
  padding: 0.7rem;
  font-weight: 500;
  font-size: 0.9rem;

  cursor: pointer;
  &__top {
    margin-bottom: 0.5rem;
    position: relative;
    .lazy-load-image-background {
      width: 100%;
    }
    img {
      width: 100%;
    }

    &__duration {
      position: absolute;
      bottom: 0.3rem;
      right: 0.3rem;
      padding: 0.2rem;
      background: #080808ec;
      border-radius: 3px;
    }
  }

  &__title {
    margin-bottom: 0.1rem;
    color: #fff;
    @include line-clamp(2);
  }

  &__details {
    display: flex;
    align-items: center;
  }

  &__channel {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;

    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin-right: 0.5rem;
      cursor: pointer;
    }
    p {
      margin-bottom: 0;
    }
  }
  .time {
    margin-left: 0.2rem;
  }
}
.details{
  margin-right: 3rem;
  margin-left: 3rem;
  color: #dddbdb;
}
.showMoreText{
  color: #c3c1c1;
  font-size: 1.5rem;
 }