@import "../../../_base.scss";

.login {
  height: 100vh;
  display: grid;
  place-items: center;
  place-items: center;
  &__container {
    background-color: $black-secondary;
    padding: 2rem;
    margin: 0 1rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      padding: 0.5rem;
      border: none;
      border-radius: 5px;
      transition: 0.3s;
      margin-bottom: 1rem;
      font-weight: 500;
      &:focus {
        outline: none;
      }
    }
    button:active {
      transform: scale(0.81);
    }
  }

  img {
    width: 130px;
    height: 130px;
    object-fit: contain;
  }
}
