@import "../../../base.scss";

.channelHeader {
  border-bottom: 1px solid $border-color;

  &__left {
    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin-right: 40px;
    }
  }
  button {
    text-transform: uppercase;
    background-color: rgb(212, 212, 212);
    color: rgb(0, 0, 0);

    font-size: 1rem;
    padding: 0.4rem;
    border-radius: 75px 75px;
  }
}
.imgChannel {
  border-radius: 50%;
}

@media (max-width: $breakpoint-medium) {
  .channelHeader {
    padding: 1.1girem 0;

    img {
      width: 50px;
      height: 50px;
    }
    h3 {
      font-size: 1rem;
      margin: 0;
    }
  }
}
.imgChannel {
  margin-right: 3rem;
}
