/* Set the font and font size for the tabs */
.nav-tabs {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    border: none;

  }
  
  /* Set the color of the tabs */
  .nav-tabs .nav-link {
    color: #bdbcbc;
    border: none;

  }
  
  /* Set the active tab color */
  .nav-tabs .nav-link.active {
    color: #cfcbcb;
    border: none;

  }
  
  /* Set the hover color for the tabs */
  .nav-tabs .nav-link:hover {
    color: #ffffff;
    border: none;

  }
  
  /* Remove the border from the active tab */
  .nav-tabs .nav-link.active {
    border-radius: 3%;
    border-bottom: 3.9px solid rgb(103, 103, 103);
    background-color: #0c0c0c;

  }
  
  /* Set the background color for the tabs */
  .nav-tabs {
    background-color: #0c0c0c;
  }
  .nav-tabs.active{
    background-color: #0c0c0c;
  }
.links{
    text-decoration: none;
    color: skyblue;
}
.links:hover{
  text-decoration: none;
  color: rgb(92, 203, 247);
}
.playlist-container {
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: .3s;
  border-radius: 9px;
}

.playlist-image {
  position: relative;
}

.playlist-image-Image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 9px;
}

.playlist-overlay {
  position: absolute;
  bottom: 0;
  left: 61%;
  width: 39%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  transition: .3s;
}



.overlay-text {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}
